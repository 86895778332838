function Footer() {
  return (
      <footer className="w-full  text-white py-4 mt-auto">
          <div className="container mx-auto text-center">
              <p className="text-gray-500">Made with <b className="text-red-700">♥️</b> by GeckoTV</p>
          </div>
      </footer>
);
}

export default Footer;
