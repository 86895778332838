function Projects() {
  return (
    <section className="py-16 px-6 text-center text-white">
      <h2 className="text-4xl font-bold mb-6">Projects</h2>
      <p className="text-lg mb-12">Some of the projects I am currently working on</p>
      <div className="flex flex-col sm:flex-row justify-center gap-6 overflow-x-auto pb-4">
        <div className="flex-shrink-0 bg-yellow-600 p-8 rounded-xl shadow-xl hover:shadow-2xl transition-all duration-300 w-full sm:max-w-xs">
          <div className="flex justify-center mb-4">
            <span className="text-6xl">🍪</span>
          </div>
          <h3 className="text-3xl font-semibold mb-2">CookieBot</h3>
          <p className="text-base mb-4">
            A Discord bot with many features for your server. Collect cookies and much more!
          </p>
          <a
            href="https://cookieapp.me"
            className="bg-gray-800 hover:bg-gray-700 text-white py-2 px-6 rounded-full transition-all duration-300 inline-block"
          >
            View Project
          </a>
        </div>
        <div className="flex-shrink-0 bg-blue-400 p-8 rounded-xl shadow-xl hover:shadow-2xl transition-all duration-300 w-full sm:max-w-xs">
          <div className="flex justify-center mb-4">
            <span className="text-6xl">📻</span>
          </div>
          <h3 className="text-3xl font-semibold mb-2">Wavy-Bot</h3>
          <p className="text-base mb-4">
            A Discord music bot with many features and great sound quality.
          </p>
          <a
            href="https://wavybot.me"
            className="bg-gray-800 hover:bg-gray-700 text-white py-2 px-6 rounded-full transition-all duration-300 inline-block"
          >
            View Project
          </a>
        </div>
      </div>
    </section>
  );
}

export default Projects;
