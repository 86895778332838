import React, { useEffect } from "react";

const Snowfall = () => {
  useEffect(() => {
    const numFlakes = 1000;
    const flakes = [];

    for (let i = 0; i < numFlakes; i++) {
      const size = Math.random() * 5 + 2;
      const positionX = Math.random() * window.innerWidth;
      const positionY = Math.random() * -1000;
      const duration = Math.random() * 5 + 5;
      const delay = Math.random() * 3;
      const horizontalSpeed = Math.random() * 0.5 - 0.25;

      flakes.push({
        size,
        positionX,
        positionY,
        duration,
        delay,
        horizontalSpeed,
      });
    }

    const snowfallContainer = document.querySelector(".snowfall-container");
    flakes.forEach((flake) => {
      const snowflake = document.createElement("div");
      snowflake.classList.add("snowflake", "absolute", "rounded-full", "bg-white", "opacity-80");
      snowflake.style.width = `${flake.size}px`;
      snowflake.style.height = `${flake.size}px`;
      snowflake.style.left = `${flake.positionX}px`;
      snowflake.style.top = `${flake.positionY}px`;
      snowflake.style.animationDuration = `${flake.duration}s`;
      snowflake.style.animationDelay = `${flake.delay}s`;
      snowflake.style.animationName = `snowfall${flake.size}`;
      snowflake.style.animationTimingFunction = "linear";
      snowflake.style.setProperty('--horizontal-speed', `${flake.horizontalSpeed}px`);

      snowfallContainer.appendChild(snowflake);
    });

    return () => {
      const snowfallContainer = document.querySelector(".snowfall-container");
      snowfallContainer.innerHTML = "";
    };
  }, []);

  return (
    <div className="snowfall-container fixed top-0 left-0 w-full h-full pointer-events-none z-50 overflow-hidden">
    </div>
  );
};

export default Snowfall;
