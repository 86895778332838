function Hero() {
  return (
    <div className="hero flex flex-col items-center justify-center h-screen bg-neutral-800 relative">
      <p className="text-7xl font-bold text-center mb-10 text-white">
        Hey, I'm Gecko!
      </p>
      <p className="text-2xl text-center mb-6 text-gray-300">
        I am a <b className="font-bold text-yellow-300">developer</b> and <b className="font-bold text-yellow-300">student</b> from Germany.
      </p>
      <div className="flex space-x-4 mb-6 drop-shadow-lg flex-wrap justify-center gap-4">
        {/* GitHub Button */}
        <a
          href="https://github.com/SilberGecko6917"
          className="bg-zinc-900 font-bold w-40 h-14 rounded-2xl flex items-center justify-center space-x-2 shadow-lg transition-all duration-300 ease-in-out hover:bg-zinc-700 hover:-translate-y-0.5 hover:scale-102"
        >
          <i className="fab fa-github text-white"></i>
          <span className="text-white text-xl">GitHub</span>
        </a>

        {/* Discord Button */}
        <a
          href="https://discord.com/users/753974250968186901"
          className="bg-indigo-600 font-bold w-40 h-14 rounded-2xl flex items-center justify-center space-x-2 shadow-lg transition-all duration-300 ease-in-out hover:bg-indigo-500 hover:-translate-y-0.5 hover:scale-102"
        >
          <i className="fab fa-discord text-white"></i>
          <span className="text-white text-xl">Discord</span>
        </a>

        {/* Mail Button */}
        <a
          href="mailto:contact@geckotv.me"
          className="bg-gray-700 font-bold w-40 h-14 rounded-2xl flex items-center justify-center space-x-2 shadow-lg transition-all duration-300 ease-in-out hover:bg-gray-600 hover:-translate-y-0.5 hover:scale-102"
        >
          <i className="fas fa-envelope text-white"></i>
          <span className="text-white text-xl">Mail</span>
        </a>
      </div>
      <div className="absolute bottom-10 flex flex-col items-center animate-bounce">
        <p className="text-yellow-300 font-bold text-sm">Scroll Down</p>
        <i className="fas fa-chevron-down text-gray-300"></i>
      </div>
    </div>
  );
}


export default Hero;