function Experiences() {
  return (
    <section className="py-16 px-6 text-center text-white">
      <h2 className="text-4xl font-bold mb-6">Experiences</h2>
      <p className="text-lg mb-12">Programming languages I have worked with</p>
      <div className="flex flex-col sm:flex-row justify-center gap-6 overflow-x-auto pb-4">
        <div className="flex-shrink-0 bg-gray-600 p-8 rounded-xl shadow-xl hover:shadow-2xl transition-all duration-300 w-full sm:w-60">
          <div className="flex justify-center mb-4">
            <span className="text-4xl">🐍</span>
          </div>
          <h3 className="text-3xl font-semibold mb-2">Python</h3>
          <p className="text-base">A versatile language for back-end development and automation.</p>
        </div>
        <div className="flex-shrink-0 bg-gray-600 p-8 rounded-xl shadow-xl hover:shadow-2xl transition-all duration-300 w-full sm:w-60">
          <div className="flex justify-center mb-4">
            <span className="text-4xl">⚡</span>
          </div>
          <h3 className="text-3xl font-semibold mb-2">JavaScript</h3>
          <p className="text-base">For building interactive web applications, both front-end and back-end.</p>
        </div>
        <div className="flex-shrink-0 bg-gray-600 p-8 rounded-xl shadow-xl hover:shadow-2xl transition-all duration-300 w-full sm:w-60">
          <div className="flex justify-center mb-4">
            <span className="text-4xl">☕</span>
          </div>
          <h3 className="text-3xl font-semibold mb-2">Java</h3>
          <p className="text-base">Coding Minecraft plugins, mods and other cool stuff.</p>
        </div>
      </div>
    </section>
  );
}
export default Experiences;
